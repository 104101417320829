import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import projects from '@/data/projects'
import projekty from '@/data/projekty'
import { useTranslator } from '@/plugins/translator'

export const usePortfolioStore = defineStore('portfolio',() =>  {
  const { locale } = useTranslator()
  const activeModel = ref()
  const view = ref<boolean>(true)
  const modelView = ref<boolean>(false)
  const activeCard = ref<string>('')
  const instructions = ref<boolean>(true)
  const setActiveCard = (card: string) => {
    activeCard.value = card
  }
  function setActiveModel(Model: string) {
    if (locale.value === 'en') {
      activeModel.value = projects.find((project) => project.model.split('.')[0] === Model)
    } else if (locale.value === 'pl') {
      activeModel.value = projekty.find((project) => project.model.split('.')[0] === Model)
    }
  }
  function turnOnModelView() {
    modelView.value = true
  }
  function turnOffModelView() {
    modelView.value = false
  }
  function resetView (){
    view.value = !view.value
  }
  function toggleInstructions() {
    instructions.value = !instructions.value
  }
  watch(locale, () => {
    if (locale.value === 'en') {
      activeModel.value = projects.find((project) => project.model.split('.')[0] === activeModel.value.model.split('.')[0])
    } else if (locale.value === 'pl') {
      activeModel.value = projekty.find((project) => project.model.split('.')[0] === activeModel.value.model.split('.')[0])
    }
  })

  return { 
    activeModel,
    view,
    modelView,
    activeCard,
    instructions,
    setActiveCard,
    setActiveModel,
    resetView,
    turnOnModelView,
    turnOffModelView,
    toggleInstructions,
  }
})