<script setup lang="ts">
</script>

<template>
    <div class="w-full h-full">
        <slot />
    </div>
</template>

<style lang="scss">
</style>