// Plik z projektami po polsku

const projekty = [
  {
    name: 'Projekt1',
    model: 'project0.glb', // nie edytować
    photo: 'project0.png', // nie edytować
    period: '05.2022 - 10.2022',
    occupation: 'Kierownik projektu',
    responsibilities: [
      'Coś tam 1',
      'Coś tam 2',
      'Coś tam 3',
      'Coś tam 4',
    ],
    description: 'Opis projektu',
  },
  {
    name: 'Projekt2',
    model: 'project1.glb', // nie edytować
    photo: 'project1.png', // nie edytować
    period: '05.2022 - 10.2022',
    occupation: 'Structural Engineer',
    responsibilities: [
      'Responsibility 1',
      'Responsibility 2',
      'Responsibility 3',
      'Responsibility 4',
    ],
    description: 'The way we travel and move has changed in the last few years. We no longer want the typical photo that all our contacts post on Instagram. We no longer want to elbow our way through crowded museums. We no longer want to view the city through the mobile lens.',
  },
  {
    name: 'Projekt3',
    model: 'project2.glb', // nie edytować
    photo: 'project2.png', // nie edytować
    period: '05.2022 - 10.2022',
    occupation: 'Structural Engineer',
    responsibilities: [
      'Responsibility 1',
      'Responsibility 2',
      'Responsibility 3',
      'Responsibility 4',
    ],
    description: 'The way we travel and move has changed in the last few years. We no longer want the typical photo that all our contacts post on Instagram. We no longer want to elbow our way through crowded museums. We no longer want to view the city through the mobile lens.',
  },
  {
    name: 'Projekt4',
    model: 'project3.glb', // nie edytować
    photo: 'project3.png', // nie edytować
    period: '05.2022 - 10.2022',
    occupation: 'Structural Engineer',
    responsibilities: [
      'Responsibility 1',
      'Responsibility 2',
      'Responsibility 3',
      'Responsibility 4',
    ],
    description: 'The way we travel and move has changed in the last few years. We no longer want the typical photo that all our contacts post on Instagram. We no longer want to elbow our way through crowded museums. We no longer want to view the city through the mobile lens.',
  },
  {
    name: 'Projekt5',
    model: 'project4.glb', // nie edytować
    photo: 'project4.png', // nie edytować
    period: '05.2022 - 10.2022',
    occupation: 'Structural Engineer',
    responsibilities: [
      'Responsibility 1',
      'Responsibility 2',
      'Responsibility 3',
      'Responsibility 4',
    ],
    description: 'Description',
  },
]

export default projekty