import { Locales } from './models'

export const en: Locales = {
  header: {
    nav: {
      about: 'About',
      portfolio: 'Portfolio',
      contact: 'Contact me ',
    },
  },
  footer: {
    menu: {
      home: 'Home',
      portfolio: 'Portfolio',
      about: 'About',
      contact: 'Contact',
    },
    contact: 'Contact',
    rights: 'PS127 Project Studio © 2023. All rights reserved.',
    developer: 'Designed and Developed by ',
  },
  home: {
    home: {
      mainColumn: {
        homeTab: {
          title: {
            first: 'Focus on ',
            emphasis1: 'precision',
            second: ' and meet your ',
            emphasis2: 'expectations.',
          },
        },
        aboutTab: {
          title: 'About',
          content: 'I am a structural engineer with a passion for design and a keen eye for detail. I have experience in the design of steel, concrete, timber and masonry structures. I am also experienced in the use of various structural analysis and design software such as ETABS, SAP2000, SAFE, RAM Concept, RAPT, SPACE GASS, Microstran, and AutoCAD.',
          button: 'Read more',
        },
        portfolioTab: {
          title: 'Portfolio',
          content: 'I have worked on a wide range of projects including residential, commercial, industrial, and institutional buildings. I have also worked on a number of infrastructure projects including bridges, culverts, and retaining walls.',
          button: 'Check out my work',
        },
        contactTab: {
          title: 'Contact',
          content: 'If you have any questions or would like to discuss a project, please feel free to contact me.',
          button: 'Get in touch',
        },
      },
      tabsReel: {
        about: 'About',
        portfolio: 'Portfolio',
        contact: 'Contact',
      },
      mobileView: {
        title: {
          first: 'Focus on ',
          emphasis1: 'precision',
          second: ' and meet your ',
          emphasis2: 'expectations.',
        },
        aboutTab: {
          title: 'About',
          content: 'I am a structural engineer with a passion for design and a keen eye for detail. I have experience in the design of steel, concrete, timber and masonry structures. I am also experienced in the use of various structural analysis and design software such as ETABS, SAP2000, SAFE, RAM Concept, RAPT, SPACE GASS, Microstran, and AutoCAD.',
          button: 'Read more',
        },
        portfolioTab: {
          title: 'Portfolio',
          content: 'I have worked on a wide range of projects including residential, commercial, industrial, and institutional buildings. I have also worked on a number of infrastructure projects including bridges, culverts, and retaining walls.',
          button: 'Check out my work',
        },
        contactTab: {
          title: 'Contact',
          content: 'If you have any questions or would like to discuss a project, please feel free to contact me.',
          button: 'Get in touch',
        },
      },
    },
    about: {
      header: 'About PS127',
      profile: {
        founder: 'Founder of PS127',
      },
      info: {
        title: 'Few words about me',
        content: {
          first: 'I am a structural engineer with a passion for design and a keen eye for detail. I have experience in the design of steel, concrete, timber and masonry structures. I am also experienced in the use of various structural analysis and design software such as ETABS, SAP2000, SAFE, RAM Concept, RAPT, SPACE GASS, Microstran, and AutoCAD.',
          second: 'I have worked on a wide range of projects including residential, commercial, industrial, and institutional buildings. I have also worked on a number of infrastructure projects including bridges, culverts, and retaining walls.',
        },
      },
    },
    contact: {
      header: {
        first: 'Let\'s work together',
        break: 'to build something great!',
      },
      form: {
        name: 'first name and last name',
        email: 'email',
        title: 'title for the message',
        phone: 'phone number',
        message: 'message',
        button: 'Send',
      },
    },
  },
  portfolio: {
    card: {
      role: 'My role: ',
      responsibilities: 'Responsibilities: ',
      description: 'Short description: ',
    },
    model: {
      instruction: {
        item1: {
          first: 'Use ',
          emphasis: 'left mouse button',
          second: ' to rotate the model.',
        },
        item2: {
          first: 'Use your ',
          emphasis: 'mouse wheel',
          second: ' to zoom in and out.',
        },
        item3: {
          first: 'Hold ',
          button: 'shift',
          emphasis: ' + left mouse button',
          second: ' to pan the model.',
        },
      },
    },
  },
}