import { Locales } from './models'

export const pl: Locales = {
  header: {
    nav: {
      about: 'O mnie',
      portfolio: 'Portfolio',
      contact: 'Napisz do mnie',
    },
  },
  footer: {
    menu: {
      home: 'Strona główna',
      portfolio: 'Portfolio',
      about: 'O mnie',
      contact: 'Kontakt',
    },
    contact: 'Kontakt',
    rights: 'Studio projektowe PS127 © 2023. Wszelkie prawa zastrzeżone.',
    developer: 'Zaprojektowano i wykonano przez ',
  },
  home: {
    home: {
      mainColumn: {
        homeTab: {
          title: {
            first: 'Skup się na ',
            emphasis1: 'precyzji',
            second: ' i spełnij swoje ',
            emphasis2: 'oczekiwania.',
          },
        },
        aboutTab: {
          title: 'O mnie',
          content: 'Jestem inżynierem konstruktorem z pasją do projektowania i wyczulonym okiem na szczegóły. Mam doświadczenie w projektowaniu konstrukcji stalowych, betonowych, drewnianych i murowanych. Mam również doświadczenie w korzystaniu z różnego rodzaju oprogramowania do analizy i projektowania konstrukcji, takiego jak ETABS, SAP2000, SAFE, RAM Concept, RAPT, SPACE GASS, Microstran i AutoCAD.',
          button: 'Dowiedz się więcej',
        },
        portfolioTab: {
          title: 'Portfolio',
          content: 'Pracowałem nad szeroką gamą projektów, w tym budynków mieszkalnych, komercyjnych, przemysłowych i instytucjonalnych. Pracowałem również nad wieloma projektami infrastrukturalnymi, w tym mostami, przepustami i ścianami oporowymi.',
          button: 'Zobacz moje portfolio',
        },
        contactTab: {
          title: 'Kontakt',
          content: 'Jeśli masz jakieś pytania lub chcesz omówić projekt, skontaktuj się z nami.',
          button: 'Napisz do mnie',
        },
      },
      tabsReel: {
        about: 'O mnie',
        portfolio: 'Portfolio',
        contact: 'Kontakt',
      },
      mobileView: {
        title: {
          first: 'Skup się na ',
          emphasis1: 'precyzji',
          second: ' i spełnij swoje ',
          emphasis2: 'oczekiwania.',
        },
        aboutTab: {
          title: 'O mnie',
          content: 'Jestem inżynierem konstruktorem z pasją do projektowania i wyczulonym okiem na szczegóły. Mam doświadczenie w projektowaniu konstrukcji stalowych, betonowych, drewnianych i murowanych. Mam również doświadczenie w korzystaniu z różnego rodzaju oprogramowania do analizy i projektowania konstrukcji, takiego jak ETABS, SAP2000, SAFE, RAM Concept, RAPT, SPACE GASS, Microstran i AutoCAD.',
          button: 'Dowiedz się więcej',
        },
        portfolioTab: {
          title: 'Portfolio',
          content: 'Pracowałem nad szeroką gamą projektów, w tym budynków mieszkalnych, komercyjnych, przemysłowych i instytucjonalnych. Pracowałem również nad wieloma projektami infrastrukturalnymi, w tym mostami, przepustami i ścianami oporowymi.',
          button: 'Zobacz moje portfolio',
        },
        contactTab: {
          title: 'Kontakt',
          content: 'Jeśli masz jakieś pytania lub chcesz omówić projekt, skontaktuj się z nami.',
          button: 'Napisz do mnie',
        },
      },
    },
    about: {
      header: 'O PS127',
      profile: {
        founder: 'Założyciel PS127',
      },
      info: {
        title: 'Kilka słów o mnie',
        content: {
          first: 'Jestem inżynierem konstruktorem z pasją do projektowania i wyczulonym okiem na szczegóły. Mam doświadczenie w projektowaniu konstrukcji stalowych, betonowych, drewnianych i murowanych. Mam również doświadczenie w korzystaniu z różnego rodzaju oprogramowania do analizy i projektowania konstrukcji, takiego jak ETABS, SAP2000, SAFE, RAM Concept, RAPT, SPACE GASS, Microstran i AutoCAD.',
          second: 'Pracowałem nad szeroką gamą projektów, w tym budynków mieszkalnych, komercyjnych, przemysłowych i instytucjonalnych. Pracowałem również nad wieloma projektami infrastrukturalnymi, w tym mostami, przepustami i ścianami oporowymi.',
        },
      },
    },
    contact: {
      header: {
        first: 'Skontaktuj się ze mną, aby',
        break: 'stworzyć coś niesamowitego!',
      },
      form: {
        name: 'imię i nazwisko',
        email: 'email',
        title: 'tytuł wiadomości',
        phone: 'numer telefonu',
        message: 'wiadomość',
        button: 'Wyślij',
      },
    },
  },
  portfolio: {
    card: {
      role: 'Moja rola: ',
      responsibilities: 'Moje obowiązki: ',
      description: 'Opis projektu: ',
    },
    model: {
      instruction: {
        item1: {
          first: 'Kliknij ',
          emphasis: 'lewy przycisk myszy',
          second: ', żeby obróć model.',
        },
        item2: {
          first: 'Użyj ',
          emphasis: 'kółka myszy',
          second: ', żeby przybliżyć lub oddalić model.',
        },
        item3: {
          first: 'Przytrzymaj ',
          button: 'shift',
          emphasis: ' + lewy przycisk myszy',
          second: ', żeby przesunąć model.',
        },
      },
    },
  },
}