<script setup lang="ts">
import { ref, watch } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid'
import { useTranslator } from '@/plugins/translator'

const { locale } = useTranslator()

const languages = [
  { name: 'english', acronym: 'En', locale: 'en' },
  { name: 'polski', acronym: 'Pl', locale: 'pl' },
]
const selectedLanguage = ref(languages.find((lang) => lang.locale === locale.value))
watch(selectedLanguage, () => {
  if (selectedLanguage.value) locale.value = selectedLanguage.value?.locale
})
</script>

<template>
    <Listbox v-model="selectedLanguage" class="z-50">
        <div class="relative">
            <ListboxButton
                class="relative h-full cursor-pointer text-primary"
                v-slot="{ open }"
            >
                <ChevronDownIcon
                    v-if="!open"
                    class="h-6 w-6"
                    aria-hidden="true"
                />
                <ChevronUpIcon
                    v-else
                    class="h-6 w-6"
                    aria-hidden="true"
                />
                {{ selectedLanguage?.acronym }}
            </ListboxButton>
  
            <Transition
                enter-active-class="transition duration-100 ease-in"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute right-0 mt-1 max-h-60 w-fit rounded-md bg-light py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="language in languages
                        "
                        :key="language.name"
                        :value="language"
                        as="template"
                    >
                        <li
                            :class="[
                                'hover:bg-secondary hover:cursor-pointer',
                                active ? 'bg-amber-100 text-amber-900' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-10 pr-4',
                            ]"
                        >
                            <span
                                :class="[
                                    selected ? 'font-medium' : 'font-normal',
                                    'block truncate',
                                ]"
                            >{{ language.name }}</span
                            >
                            <span
                                v-if="selected"
                                class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                            >
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </Transition>
        </div>
    </Listbox>
</template>

<style scoped lang="scss">

  button {
    @apply flex items-center text-light border box-border border-transparent rounded-md py-1 pr-2;
    &:hover {
      @apply transition-all duration-200 border-secondary cursor-pointer;
    }
    &:active {
      @apply transition-all duration-200 border-secondary text-secondary cursor-pointer scale-95;
    }
  }
</style>