<script setup lang="ts">
import { ArrowLeftOnRectangleIcon, CubeTransparentIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { LightBulbIcon } from '@heroicons/vue/24/outline'
import { Button } from '@/components'
import { useRouter } from 'vue-router'
import { usePortfolioStore } from '@/store/portfolio'
import { storeToRefs } from 'pinia'
import { useMediaQuery } from '@vueuse/core'

const isMobile = useMediaQuery('screen and (max-width: 768px)')
const portfolioStore = usePortfolioStore()
const { resetView, turnOffModelView, toggleInstructions } = portfolioStore
const { modelView } = storeToRefs(portfolioStore)
const router = useRouter()

</script> 

<template>
    <div class="flex gap-2">
        <Button @click="router.back()" v-if="!modelView"><ArrowLeftOnRectangleIcon class="h-6 w-6"/></Button>
        <Button @click="() => {
            turnOffModelView()
        }" v-if="modelView === true"><XMarkIcon class="h-6 w-6"/></Button>
        <Button @click="resetView" v-if="modelView && !isMobile"><CubeTransparentIcon class="h-6 w-6"/></Button>
        <Button @click="toggleInstructions" v-if="modelView && !isMobile"><LightBulbIcon class="h-6 w-6"/></Button>
    </div>
</template>

<style scoped lang="scss">  
</style>
