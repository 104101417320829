<script setup lang="ts">
import { Container } from '@/components'
import { Header, Footer } from './_components'
import { ref, watch } from 'vue'

import { useRoute } from 'vue-router'

const route = useRoute()
const isPortfolio = ref(route.path === '/portfolio')

watch(() => route.path, (path) => {
  isPortfolio.value = path === '/portfolio'
})

// const isMobile = ref(false);
const home = ref(true)

onscroll = () => {
  if (window.scrollY > 0) {
    home.value = false
  } else {
    home.value = true
  }
}

</script>

<template>
    <div id="layout">
        <Header :home="home"/>
        <main>
            <!-- the Container is making the width difference between Header/Footer elements and main content -->
            <Container>
                <slot />
            </Container>
        </main>
        <Footer />
    </div>
</template>

<style lang="scss">
#layout {
    @apply flex flex-col min-h-screen w-screen bg-primary items-center;
    // overflow-x: hidden;
    footer, header {
        // to make the width of the header/footer elements the same as the main content width add max-w-7xl
        @apply flex justify-center px-4 md:px-8 xl:px-12;
    }
    main {
        @apply flex justify-center overflow-hidden;
    }
}
</style>

