<script setup lang="ts">
import { Logo, Button, Container } from '@/components'
import { LanguageListbox, Menu } from './_components'
import { useMediaQuery, onClickOutside } from '@vueuse/core'
import { ref, watch } from 'vue'
import { Bars3Icon } from '@heroicons/vue/24/outline'
import { RouterLink, useRoute } from 'vue-router'
import { useTranslator } from '@/plugins/translator'
// import { useHomeStore } from '@/store/home'

defineProps({
  home: {
    type: Boolean,
    default: true,
  },
})

// const homeStore = useHomeStore()
// const { setActiveTab } = homeStore

const { tr } = useTranslator()

const route = useRoute()

const isHome = ref(route.path === '/')
const isPortfolio = ref(route.path === '/portfolio')

watch(() => route.path, (path) => {
  // if (path === '/') setActiveTab('')
  isHome.value = path === '/'
  isPortfolio.value = path === '/portfolio'
})

const isMobile = useMediaQuery('(max-width: 768px)')
const visibleMenu = ref(false)

const closeMenu = () => {
  setTimeout(() => {
    visibleMenu.value = false
  }, 10)
}

const target = ref(null)
onClickOutside(target, () => visibleMenu.value = false)
</script>

<template>
    <header ref="target" :class="[
        'sticky transition-all duration-500 top-0 backdrop-blur-md w-full z-50 flex  h-16 flex-col',
        home && !isMobile ? 'bg-opacity-0' : 'bg-opacity-20 border-b border-light',
        isPortfolio && 'bg-opacity-20 border-b border-light',
        visibleMenu && isMobile ? 'border-transparent bg-primary bg-opacity-70' : 'border-light',
    ]">
        <div class="flex justify-center">
            <Container v-if="isMobile" class="flex items-center justify-between h-16">
                <button v-if="!isPortfolio" @click="visibleMenu = !visibleMenu" class="text-light z-50 hover:text-secondary transition-all duration-300">
                    <Bars3Icon class="h-8 w-8" />
                </button>
                <div v-if="!isPortfolio" class="absolute left-0 top-0 h-full w-full flex items-center justify-center z-40">
                    <a v-if="isHome" href="#" v-scroll-to="'#home'" class="home"><Logo color="secondary" /></a>
                    <RouterLink v-else to="/" class="home"><Logo color="secondary" /></RouterLink>
                </div>
                <Menu v-if="isPortfolio" />
                <nav>
                    <LanguageListbox />
                </nav>
            </Container>
            <Container v-else class="flex items-center justify-between">
                <a v-if="isHome" href="#" v-scroll-to="'#home'" class="home"><Logo color="secondary" /></a>
                <RouterLink v-else-if="!isPortfolio && !isHome" to="/" class="home"><Logo color="secondary" /></RouterLink>
                <Menu v-if="isPortfolio"/>
                <nav>
                    <TransitionGroup :duration="500">
                        <a href="#" v-scroll-to="'#about'" v-if="!home && !isPortfolio" >{{ tr().header.nav.about }}</a>
                        <RouterLink  to="/portfolio" v-if="!home && !isPortfolio">{{ tr().header.nav.portfolio }}</RouterLink>
                    </TransitionGroup>
                    <LanguageListbox />
                    <a  href="#" v-scroll-to="'#contact'" class="contact" v-if="!isPortfolio"><Button>{{ tr().header.nav.contact }}</Button></a>
                </nav>
            </Container>
        </div>
        <TransitionGroup name="fade" :duration="500">
            <nav class="mobile" v-if="visibleMenu">
                <a href="#" @click="closeMenu" v-scroll-to="'#about'" class="mobile">{{ tr().header.nav.about }}</a>
                <a href="#" @click="closeMenu" v-scroll-to="'#portfolio'" class="mobile">{{ tr().header.nav.portfolio }}</a>
                <a  href="#" @click="closeMenu" v-scroll-to="'#contact'" class="contact mobile">{{ tr().header.nav.contact }}</a>
            </nav>
        </TransitionGroup>
    </header>
</template>

<style scoped lang="scss">
  .v-enter-active, .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from, .v-leave-to {
    opacity: 0;
  }
  .fade-move, .fade-enter-active, .fade-leave-active {
    transition: all 0.5s ease;
  }

  .fade-leave-active {
    position: absolute;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  nav {
    @apply flex items-center;
    &:not(.mobile) {
      @apply gap-5;
    }
    &.mobile{
      @apply flex-col fixed top-16 left-0 w-full  bg-primary bg-opacity-70 backdrop-blur-md z-40 pb-5 border-b border-light;
      a {
        @apply flex justify-center items-center p-1 transition-all duration-200 text-2xl uppercase border-y-2 border-transparent text-light w-full box-border text-center;
        &:hover {
          @apply text-secondary border-secondary;
        }
      }
    }
  }

  a:not(.contact, .home, .mobile) {
    @apply relative text-light border-2 box-border border-transparent list-none;
    &::after {
      @apply absolute top-0 left-0 transition-all duration-200 box-border border-2 border-transparent cursor-pointer h-full w-0 content-[""];
    }
    &:hover::after, &:active::after {
      @apply w-full border-b-secondary ;
    }
    &:active, &.active {
      @apply transition-all duration-200 text-secondary cursor-pointer scale-95;
    }
  }
</style>