import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'
import Vue3TouchEvents from 'vue3-touch-events'
import AsImage from '@awesome-image/image'
import '@awesome-image/image/dist/style.css'
import { imageUrlGeneratorFP } from '@awesome-image/services'
import { MotionPlugin } from '@vueuse/motion'
import { translator } from './plugins/translator'
import router from './router'
import './main.scss'

const pinia = createPinia()

const app = createApp(App)
app.use(router)
app.use(VueScrollTo, {
  duration: 500,
  easing: 'ease',
  offset: -64,
})
app.use(pinia)
app.use(Vue3TouchEvents, {
  swipeTolerance: 5,
})
app.use(AsImage, {
  responsive: true,
  placeholder: true,
  // progressive: true,
  SSR: true,
  duration: 1,
  smooth: true,
  lazy: true,
  imageUrlGenerator: imageUrlGeneratorFP,
})
app.use(translator)
app.use(MotionPlugin)

// app.use(GesturePlugin)
// .use(vue3dLoader)

app.mount('#app')
