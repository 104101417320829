<script setup lang="ts">
import { Container, Logo } from '@/components'
import { useRoute } from 'vue-router'
import { ref, watch } from 'vue'
import { RouterLink } from 'vue-router'
import { useMediaQuery } from '@vueuse/core'
import { EnvelopeIcon, PhoneIcon } from '@heroicons/vue/24/outline'
import { useTranslator } from '@/plugins/translator'

const { tr } = useTranslator()

const isMobile = useMediaQuery('screen and (max-width: 768px)')
const route = useRoute()

const isPortfolio = ref(route.path === '/portfolio')

watch(() => route.path, (path) => {
  isPortfolio.value = path === '/portfolio'
})

</script>

<template>
    <footer v-if="!isPortfolio">
        <Container class="container">
            <div class="col first">
                <Logo color="light"/>
                <div v-if="isMobile" class="flex gap-5">
                    <a href="tel:+48666950872"><PhoneIcon class="h-8 w-8"/></a>
                    <a href="mailto:pracownia@ps127.pro"><EnvelopeIcon class="h-8 w-8"/></a>
                </div>
            </div>
            <div class="col">
                <h3>Menu</h3>
                <ul>
                    <li class="menu-link"><a href="#" v-scroll-to="'#home'">{{ tr().footer.menu.home }}</a></li>
                    <li v-if="isMobile" class="menu-link"><a href="#" v-scroll-to="'#portfolio'">{{ tr().footer.menu.portfolio }}</a></li>
                    <li v-else class="menu-link"><RouterLink to="/portfolio">{{ tr().footer.menu.portfolio }}</RouterLink></li>
                    <li class="menu-link"><a href="#" v-scroll-to="'#about'">{{ tr().footer.menu.about }}</a></li>
                    <li class="menu-link"><a href="#" v-scroll-to="'#contact'">{{ tr().footer.menu.contact }}</a></li>
                </ul>
            </div>
            <div class="col" v-if="!isMobile">
                <h3>{{ tr().footer.contact }}</h3>
                <ul>
                    <li><PhoneIcon class="h-6 w-6"/></li>
                    <li class="menu-link"><a href="tel:+48666950872">+48 666 950 872</a></li>
                    <li><EnvelopeIcon class="h-6 w-6"/></li>
                    <li class="menu-link"><a href="mailto:pracownia@ps127.pro">pracownia@ps127.pro</a></li>
                </ul>
            </div>
        </Container>
        <div class="additional">
            <p>{{ tr().footer.rights }}</p>
            <p>{{ tr().footer.developer }}<a href="https://stanbyte.com/" target="_blank">StanByte</a></p>
        </div>
        <div class="light"></div>
    </footer>
</template>

<style scoped lang="scss">
.menu-link {
  color: var(--light);
  text-decoration: none;
  &:hover {
    color: var(--secondary);
  }
}

h3, li {
  color: var(--light);
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

li {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 300;
}

footer {
  width: 80%;
  margin: 50px auto 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.container {
  display: flex;
  // flex: 1;
  justify-content: space-between;
  width: 100%;
  z-index: 20;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.col {
  color: var(--light);
  @media screen and (max-width: 768px) {
    padding-inline: 0;
    padding-block: 20px;
  }
  &:not(:last-of-type){
    padding-right: 80px;
  }
  &:not(:first-of-type){
    padding-left: 80px;
    border-left: 1px solid var(--secondary);
    @media screen and (max-width: 768px) {
      padding-left: 0;
      border-left: none;
      border-top: 1px solid var(--secondary);
      padding-bottom: 10px;
    }
  }
  &.first {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-direction: row;
      align-items: center;
      padding-right: 0;
    }
  }
}

.menu-link:hover {
  color: var(--secondary);
}
.additional {
  margin-top: 20px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    border-top: 0.5px solid var(--secondary);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  p {
    color: var(--light);
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 0.5rem;
    a {
      color: var(--light);
      text-decoration: none;
      &:hover {
        color: var(--secondary);
      }
    }
  }
}

.light {
  @apply absolute top-1/3 left-[20px] h-2/5 w-1/2 blur-[150px] rounded-full;
  z-index: 0 !important;
  background: radial-gradient(rgba(0,0,0,0), #3AD96A, #3AD96A);
}

</style>